import { queryChannelSourceByWebsite } from '@/api/common'
import { CommonError, CommonRight, Email } from '@icons'
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  Typography,
} from '@mui/material'
import { SxProps } from '@mui/system'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { submitSubscribe } from 'src/api/account'
import FormTip from 'src/components/form/FormTip'
import fbq from 'src/utils/fpixel'
import { GoogleTagManager } from 'src/utils/gtm'
import { emailValidateRules } from 'src/utils/helpers/validate'
import { SubscribeFormData } from 'types/account'

interface FormData {
  email: string
}

const defaultErrorMsg = 'Please enter a valid email address'

const inputSx: SxProps = {
  pl: 2.5,
  pr: {
    xs: 2.5,
    md: '136px',
  },
  py: { xs: '19px', lg: '15px' },
  // mr: {
  //   lg: '44px',
  // },
  mb: {
    xs: 4.5,
    lg: 0,
  },
  alignSelf: {
    xs: 'stretch',
    md: 'center',
  },
  flex: {
    xs: 'none',
    md: 1,
  },
  // color: 'text_medium.main',
  fontSize: 15,
  lineHeight: 1.5,
  border: '1px solid #E0E0E0',
  borderRadius: '12px',
  bgcolor: '#fff',
  height: 'auto',
  color: '#B8B8B8',
  '& input': {
    padding: 0,
    height: 'auto',
    fontSize: 15,
    lineHeight: '23px',
    color: '#1d1d1d',
  },
  '& input::-webkit-input-placeholder': {
    color: '#B8B8B8',
    opacity: 1,
  },
  '&.Mui-focused': {
    borderColor: '#0051cf',
  },
}

const subscribeBtnSx: SxProps = {
  letterSpacing: '-0.06em',

  px: {
    md: 2,
    xs: 1.5,
  },
  position: {
    lg: 'absolute',
  },
  right: 8,
  top: {
    lg: '50%',
  },
  transform: {
    lg: 'translateY(-50%)',
  },
  bgcolor: '#0051cf',
  '&:hover': {
    bgcolor: 'rgba(0, 81, 207, .9)',
  },
  color: 'common.white',
  borderRadius: { xs: '80px', lg: '8px' },
  minWidth: {
    md: 'initial',
    xs: 178,
  },
  height: {
    lg: 39,
    md: 47,
    xs: 50,
  },
  fontSize: {
    xl: 15,
    xs: 17,
  },
  m: '0 auto',
  fontWeight: 600,
  alignSelf: {
    xs: 'center',
    md: 'initial',
  },
  '&.Mui-disabled': {
    bgcolor: 'ref.gray.97',
    color: 'ref.gray.75',
    border: '1px solid #d7d7d7',
  },
}

type msg = {
  message: string
  type: 'success' | 'error'
}

export const Subscribe = () => {
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState<msg>({
    message: '',
    type: 'error',
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>()

  // 处理邮箱输入空格
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'email' && /\s/.test(value[name])) {
        setValue('email', value[name].replace(/\s/g, ''))
      }
    })
    return () => subscription.unsubscribe()
  }, [setValue, watch])

  // 提交
  const onSubmit = handleSubmit(async (data) => {
    setMsg({ type: 'error', message: '' })
    setLoading(true)

    // 获取来源
    const origin = location.origin
    const referer = document.referrer
    let source = 'others'

    if (
      referer === '' ||
      `${origin}/` === referer ||
      referer.includes(location.hostname)
    ) {
      source = 'direct'
    } else {
      const hostname = referer.includes('.')
        ? new URL(referer).hostname
        : referer
      const res = await queryChannelSourceByWebsite(
        hostname.includes('www.') ? hostname.replace(/www\./, '') : hostname
      )

      if (res.data) {
        const hostSource = res.data.value.split('_')
        if (hostSource[hostSource.length - 1]) {
          source = hostSource[hostSource.length - 1].toLocaleLowerCase()
        }
      }
    }

    const icClickIdCookie = Cookies.get('__ir_click_id__')

    const params: SubscribeFormData = { ...data, source, entrance: 'home' }

    if (icClickIdCookie) {
      params.clickId = icClickIdCookie
      params.customTags = ['affiliate_impact']
    }

    const res = await submitSubscribe(params)
    setLoading(false)
    if (res.code !== 0) {
      setMsg({
        type: res.code === 3050003 ? 'success' : 'error',
        message: res.msg || 'Subscription failed!',
      })
      return
    }
    GoogleTagManager.customEvent('subscribe_success', { source: 'home' })
    fbq.subscribe({ value: res.data?.id as number, currency: 'USD' })
    setMsg({ type: 'success', message: 'You have successfully subscribed!' })
  })

  return (
    <Box component="form" noValidate onSubmit={onSubmit}>
      <Controller
        name="email"
        control={control}
        defaultValue=""
        rules={emailValidateRules}
        render={({ field }) => (
          <>
            <FormControl
              error={!!errors.email}
              variant="standard"
              sx={{ display: 'block' }}
            >
              <Box
                sx={{
                  display: 'flex',
                  position: 'relative',
                  flexDirection: { xs: 'column', lg: 'row' },
                  borderRadius: 0,
                }}
              >
                <InputBase
                  sx={inputSx}
                  {...field}
                  placeholder="Your Email Address"
                  inputProps={{ 'aria-label': 'Your Email Address' }}
                />

                <IconButton
                  disableRipple
                  type="submit"
                  disabled={loading}
                  sx={subscribeBtnSx}
                  size="small"
                  aria-label="subscribe"
                  id="home-subscription-button"
                >
                  <Email
                    sx={{
                      fontSize: { xs: 30, lg: 36 },
                      '& path': {
                        fill: '#fff',
                      },
                    }}
                  />
                </IconButton>

                <FormHelperText
                  component="div"
                  sx={{
                    position: 'absolute',
                    alignSelf: 'flex-start',
                    top: {
                      lg: '55px',
                      xs: '63px',
                    },
                  }}
                >
                  <FormTip
                    visible={!!errors.email || !!msg.message}
                    sx={{ display: 'flex', alignItems: 'center', py: 0.5 }}
                  >
                    {msg.type == 'success' && !errors.email ? (
                      <CommonRight
                        inheritViewBox
                        sx={{
                          fontSize: '18px !important',
                          color: 'success.main',
                        }}
                      />
                    ) : (
                      <CommonError
                        inheritViewBox
                        sx={{
                          fontSize: '18px !important',
                          color: 'text_error.main',
                        }}
                      />
                    )}
                    <Typography
                      color={
                        msg.type == 'success' && !errors.email
                          ? 'success.main'
                          : 'text_error.main'
                      }
                      sx={{
                        fontSize: 14,
                        lineHeight: 1.2,
                        ml: '3px',
                      }}
                      // variant="labelSmallRegular"
                    >
                      {errors.email?.type === 'pattern' ||
                      errors.email?.type === 'required'
                        ? defaultErrorMsg
                        : msg.message}
                    </Typography>
                  </FormTip>
                </FormHelperText>
              </Box>
            </FormControl>
          </>
        )}
      />
    </Box>
  )
}
