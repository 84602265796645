type SEOConfig = {
  title: string
  description: string
  keywords?: string
}

export const homePage = {
  title:
    'Dreo - Elevating Smart Living | Dreo Official Brand Website',
  description:
    'DREO is dedicated to creating whole-house smart appliances & cooking appliances such as home space heaters and DREO air purifiers for consumers to bring you the most comfortable experience at competitive prices and superior quality.',
  keywords: 'dreo,dreo tower fan,dreo fan',
}

export type FaqSpecialProductHandle =
  | 'dreo-atom-one-space-heater'
  | 'dreo-macro-pro-air-purifier'

export const faqs: { [K in FaqSpecialProductHandle]: SEOConfig } = {
  'dreo-atom-one-space-heater': {
    title: 'Dreo Product Support Services&Faq - Dreo',
    description:
      'If you want more information about portable home heater products support services, Dreo has collected a lot of questions and answers related to the best portable heater, click and learn.',
  },
  'dreo-macro-pro-air-purifier': {
    title: 'Dreo Product Support Services&Faq - Dreo',
    description:
      'If you want more information about portable home heater products support services, Dreo has collected a lot of questions and answers related to the best portable heater, click and learn.',
    keywords:
      'product support services,portable heater for home,best portable heater',
  },
}

export default {
  homePage,
}
