import AppContainer from '@/layouts/AppContainer'
import {
  FacebookPositive,
  InstagramPositive,
  TikTokPositive,
  TwitterPositive,
  YouTubePositive,
} from '@icons'
import { Box, IconButton, Typography } from '@mui/material'
import { Subscribe } from '../Subscribe'

export default function Subscription() {
  return (
    <Box
      sx={{
        pt: { xs: '60px', lg: '83px' },
        pb: { xs: '48px', lg: '83px' },
        backgroundColor: '#f9f9f9',
      }}
    >
      <AppContainer maxWidth="xl">
        <Box
          sx={{
            display: {
              xs: 'block',
              lg: 'flex',
            },
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              mb: {
                xs: '84px',
                md: '89px',
                lg: 0,
              },
              mx: {
                xs: 'auto',
                lg: 0,
              },
              flex: {
                xs: 1,
                lg: 'inherit',
              },
              width: {
                lg: 512,
                xl: 640,
              },
              maxWidth: {
                xs: '100%',
                md: 342,
                lg: '100%',
              },
            }}
          >
            <Box className="text-wrapper" sx={{ mb: 0.63 }}>
              {/* <SubscriptionTitle>Subscription</SubscriptionTitle> */}
              <Typography
                mb={1}
                color="#000"
                textAlign={{ xs: 'center', lg: 'left' }}
                letterSpacing="-0.4px"
                fontWeight={700}
                fontSize={20}
              >
                Subscribe to our newsletter
              </Typography>
            </Box>
            <Typography
              color="rgba(128, 128, 128, 1)"
              textAlign={{ xs: 'center', lg: 'left' }}
              sx={{
                fontSize: 13,
                m: '0 auto',
                mb: { xs: 3, lg: 2 },
                letterSpacing: '-0.26px',
              }}
            >
              Be the first to hear about exclusive offers, product news,
              campaigns, and more.
            </Typography>
            <Subscribe />
          </Box>

          <Box
            px={{ xs: '39px', md: 0 }}
            ml={{ xs: 0, lg: '40px' }}
            display={{ xs: 'block', lg: 'flex' }}
            flexWrap={{ xs: 'nowrap', lg: 'wrap' }}
            sx={{
              px: 0,
              ['@media (min-width: 400px)']: {
                px: '39px',
              },
              ['@media (min-width: 768px)']: {
                px: 0,
              },
            }}
            overflow="hidden"
          >
            <Box
              className="text-wrapper"
              sx={{
                mb: 3,
                display: 'flex',
                justifyContent: 'center',
                width: { lg: '100%' },
                alignItems: 'flex-end',
              }}
            >
              <Typography
                color="#000"
                letterSpacing="-0.26px"
                fontSize={13}
                fontWeight={700}
              >
                Explore #Dreo
              </Typography>
            </Box>

            <Box
              sx={{
                pt: {
                  lg: 0,
                  xs: 1,
                },
                width: { xs: 'auto', lg: '100%' },
                m: '0 auto',
                display: 'flex',
                '& svg': {
                  fontSize: 24,
                },
                justifyContent: 'space-around',
                '&> a': {
                  m: '0',
                  p: 0,
                },
                alignItems: 'center',
                ['@media (min-width: 400px)']: {
                  width: 264,
                  justifyContent: 'center',
                  '&> a': {
                    m: '0 16px',
                  },
                },
                ['@media (min-width: 992px)']: {
                  width: 'auto',
                  justifyContent: 'space-around',
                  alignItems: 'flex-start',
                  '&> a': {
                    m: 0,
                    mr: { md: 4.5 },
                    '&:last-child': {
                      mr: 0,
                    },
                  },
                },
              }}
            >
              <IconButton
                disableRipple
                href={'https://twitter.com/DreoOfficial'}
                aria-label="Twitter Dreo"
                target={'_blank'}
                id="home-twitter-link"
              >
                <TwitterPositive />
              </IconButton>
              <IconButton
                disableRipple
                href={'https://www.instagram.com/dreo.kitchen/'}
                aria-label="Instagram Dreo"
                target={'_blank'}
                id="home-instagram-link"
              >
                <InstagramPositive />
              </IconButton>
              <IconButton
                disableRipple
                href={'https://www.facebook.com/DreoHome'}
                aria-label="Facebook Dreo"
                target={'_blank'}
                id="home-facebook-link"
              >
                <FacebookPositive />
              </IconButton>

              <IconButton
                disableRipple
                href={
                  'https://www.youtube.com/channel/UCePZ-v6YOhmyax4Li2sRZAA'
                }
                aria-label="YouTube Dreo"
                target={'_blank'}
                sx={{
                  p: 0,
                }}
                id="home-youtube-link"
              >
                <YouTubePositive />
              </IconButton>
              <IconButton
                disableRipple
                href={'https://www.tiktok.com/@dreohome'}
                target={'_blank'}
                sx={{
                  p: 0,
                }}
                id="home-tikTok-link"
              >
                <TikTokPositive />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </AppContainer>
    </Box>
  )
}
