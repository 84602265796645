/*
 * @Author: Walker Qin
 * @Description:
 * @Date: 2022-09-14 14:26:35
 * @LastEditTime: 2023-02-17 11:21:19
 */
import { Validate } from 'react-hook-form'

// email
export const emailValidateRules = {
  required: true,
  maxLength: 255,
  pattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // /^[A-Za-z0-9]+([._\\-]*[A-Za-z0-9])*@([A-Za-z0-9]+[-A-Za-z0-9]*[A-Za-z0-9]+.){1,63}[A-Za-z0-9]+$/,
}

// 昵称
export const nicknameRules = {
  // 1-30个字符 不能全为空格
  pattern: /^.{1,30}$/,
  required: true,
  validate: {
    valid: (value: string | null) => {
      if (typeof value === 'string') {
        return !!value.trim()
      }
      return false
    },
  },
}

// 密码
export const passwordRules = {
  required: true,
  //包含大写字母、小写字母和至少一个数字或特殊字符
  pattern:
    // eslint-disable-next-line no-useless-escape
    /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[$@$!%*#?&,./|\[\]\-+_=\(\)~<>《》¥{}`、;:"'「」£¿€…])?.{8,20}$/,
  // pattern: /^(?=.*[a-zA-Z$@$!%*#?&])[a-zA-Z0-9~!@#$%^&*]{8,20}$/,
}

// first name && last name rules
export const nameRules = {
  required: true,
  minLength: 1,
  maxLength: 30,
  // 大写字母、小写字母
  // pattern: /^[A-Za-z]{2,20}$/,
  pattern: /^([a-zA-Z0-9'_\-,.]+(\s(?!\s)|$)){1,30}$/,
}

// 手机号
export const phoneRules = {
  required: true,
  minLength: 8,
  maxLength: 11,
}

// 生日不晚于今天
const validate: Validate<string | undefined> = (value) => {
  if (value) return new Date(value).getTime() <= new Date().getTime()
  return true
}
/**
 * 生日校验规则
 */
export const birthDateRules = {
  required: false,
  validate: {
    valid: validate,
  },
}

// 时间不早于2021/03/01，不晚于今天
const purchasedDateValidate: Validate<string | undefined> = (value) => {
  if (value)
    return new Date(value).getTime() >= new Date('2021/03/01').getTime()
  return false
}
export const purchasedDateRules = {
  required: true,
  validate: {
    valid: purchasedDateValidate,
  },
}

export const amazonReg =
  /^([0-9]{3}-[0-9]{7}-[0-9]{7})|([\da-zA-Z]{3}-[\da-zA-Z]{4}-[\da-zA-Z]{6})$/
export const walmartReg = /^[\d]{13,15}|^#[\d]{7}-[\d]{6}|^\d{7}-\d{6,8}$/
export const webReg =
  /^(DREO[0-9_]{4,10})|([\da-zA-Z]{3}-[\da-zA-Z]{4}-[\da-zA-Z]{6})$/
export const otherReg = /^CS[0-9]{8}$/

// 查找 model 正则表达式
export const modelFindReg = /DR-[a-z\d]+/i

// 配件 tag
export const accessoriesTagReg = /^accessories:/

export const orderNumberPattern = {
  'buyway.web': webReg,
  'buyway.amazon': amazonReg,
  'buyway.walmart': walmartReg,
  'buyway.other': otherReg,
  'buyway.kickstarter': /^\d{1,6}$/,
  default: /^\d*$/,
}

export const warrantyOrderNumberPattern: { [K in string]: RegExp } = {
  'Dreo Official Website': webReg,
  Amazon: amazonReg,
  'Walmart.com': walmartReg,
  default: /^\d*$/,
}
