import { BoxProps, Stack } from '@mui/material'
import { ReactNode } from 'react'

const FormTip = ({
  visible,
  children,
  sx = {},
  ...restProps
}: {
  visible: boolean
  children?: ReactNode
} & BoxProps) => {
  return (
    <Stack
      component={'span'}
      direction={'row'}
      sx={{
        display: {
          xs: visible ? 'flex' : 'none',
          md: 'flex',
        },
        opacity: visible ? 1 : 0,
        transition: 'opacity 0.2s ease',
        ...sx,
      }}
      {...restProps}
    >
      {children}
    </Stack>
  )
}

export default FormTip
