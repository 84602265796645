/*
 * @Author: Walker Qin
 * @Description:
 * @Date: 2022-09-14 14:26:35
 * @LastEditTime: 2022-11-25 14:24:45
 */
import axios from 'axios'
import {
  CONF_ADVERTISE,
  COUNTRY_LIST,
  PRODUCT_CATEGORY,
  S3_UPLOAD_URL,
  SYS_DICT,
} from 'constants/config/api'
import httpClient, { HttpData } from 'src/utils/helpers/http'
import { Country, Dictionary, ProductDictionary } from 'types/common'

export const getFileBlobData = (fileUrl: string) =>
  httpClient.get(fileUrl, { responseType: 'arraybuffer' })

export const downloadPdfByUrl = (
  fileUrl: string,
  onDownloadProgress?: (progressEvent: any) => void
) =>
  axios.get<{ data: Blob }>(fileUrl, {
    responseType: 'blob',
    onDownloadProgress,
  })

/**
 * 查询字典数据公共接口
 * @param type 字典类型
 * @returns
 */
export const queryDict = (type: string) =>
  httpClient.get<HttpData<Array<Dictionary>>>(`${SYS_DICT}?parentCode=${type}`)

/**
 * 查询国家列表
 * @returns
 */
export const getCountryList = () =>
  httpClient.get<HttpData<Array<Country>>>(`${COUNTRY_LIST}`, {
    headers: {
      lang: 'en',
    },
  })

/**
 * 查询issue列表
 * @returns
 */
export const getIssueList = () =>
  httpClient.get<HttpData<Array<Dictionary>>>(
    `${SYS_DICT}?parentCode=issue.type`
  )

/**
 * 查询购买渠道列表
 * @returns
 */
export const getPurchasedChannelList = () =>
  httpClient.get<HttpData<Array<Dictionary>>>(`${SYS_DICT}?parentCode=buyway`)

/**
 * 查询账号问题列表
 * @returns
 */
export const queryAccountIssueList = () =>
  httpClient.get<HttpData<Array<Dictionary>>>(
    `${SYS_DICT}?parentCode=issue.type.acc`
  )

/**
 * 查询订单问题列表
 * @returns
 */
export const getSupportPurchaseChannelList = () =>
  httpClient.get<HttpData<Array<Dictionary>>>(
    `${SYS_DICT}?parentCode=issue.order`
  )

/**
 * 查询横幅活动设置
 * @returns
 */
export const getAnnouncementBarConf = () =>
  httpClient.get<
    HttpData<{
      home: {
        advertise: { showHomeHeadActivity: boolean }
      }
    }>
  >(
    `${CONF_ADVERTISE}`
    // `/api/getUtcDate`
  )

/**
 * 查询产品分类数据
 * @returns
 */
export const getProductCategory = () =>
  httpClient.get<HttpData<ProductDictionary>>(`${PRODUCT_CATEGORY}`)

/**
 * S3预签名接口获取
 * @param suffix 文件后缀
 * @returns
 */
export const getS3UploadUrl = (suffix: string) =>
  httpClient.post<HttpData<{ resourceUrl: string; preSignedUrl: string }>>(
    `${S3_UPLOAD_URL}?suffix=${suffix}`
  )

/**
 * 查询来源
 * @returns
 */
export const queryChannelSourceByWebsite = (hostname: string) =>
  httpClient.get<HttpData<Dictionary>>(`${SYS_DICT}/code?code=${hostname}`)

/**
 * 获取服务器时间
 * @returns
 */
export const getUtcDate = () =>
  httpClient.get<HttpData<{ date: Date | string; timestamp: number }>>(
    `/api/getUtcDate`
  )
